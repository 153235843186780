.modal {
    &.modal-flex-card {
        .modal-dialog {
            overflow: visible;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            overflow: visible;
        }
    }
}
